import React from "react";

const Befriend = () => {
  const images = [
    "img/crocodile/dexeagle.png",
    "img/crocodile/tg.png",
    "img/crocodile/X.png",
    "img/crocodile/inst1.png",
    "img/crocodile/tik.png",
  ];

  return (
    <div className="px-5 lg:mb-[40%] mb-[100%]">
      <div className="lg:h-[90vh] flex w-full items-center justify-center">
        <img src="/img/crocodile/befriendimg.png" className=" z-10" alt="" />
      </div>
      <div data-aos="fade-up" className="relative z-[500]">
        <div className="border-[1px] absolute w-full  rounded-lg border-black p-4 flex lg:flex-row flex-col items-center justify-center lg:gap-[10%] z-50 bg-white">
          <img src="/img/crocodile/befriend.gif" alt="" />
          <div className="flex flex-col items-center gap-5">
            <p className="lg:text-[110px] text-center leading-none lapsus">
              Be Friends <br />
              with ALF
            </p>
            <p className="text-[32px] peralta-regular">Join and Follow</p>
            <div className="flex flex-row gap-3 ">
              {images.map((image, index) => (
                <img
                  width={50}
                  className="flex cursor-pointer w-[60%]"
                  key={index}
                  src={image}
                  alt=""
                />
              ))}
            </div>
          </div>
        </div>
        <div className="bg-[#2FE6FF] w-full h-[74.5vh] rounded-lg absolute top-4 left-2"></div>
      </div>
    </div>
  );
};

export default Befriend;
