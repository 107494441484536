import React, { useState } from "react";
import axios from "axios";

// Import Custom Components
import Card from "../game/card";

// Import Card Constants
import { cardsData } from "../game/cards";

// Import Custom Types
import { CardType } from "../../types";

// Import Wagmi Hooks
import { useAccount } from "wagmi";

function Game() {
  // time state
  const [startTime, setStartTime] = useState<number | null>(null);
  // states
  let [cardsState, setCardsState] = useState<CardType[]>(cardsData);
  // kep first card info
  let [firstCard, setFirstCard] = useState<CardType | null>(null);
  // is it first click?
  let [secondClick, setSecondClick] = useState<boolean>(false);
  // set flag to wait for 1500ms
  let [wait, setWait] = useState<boolean>(false);

  const { address, isConnected } = useAccount();

  // functions
  const checker = async (card: CardType) => {
    if (firstCard && card.name === firstCard.name) {
      card["passed"] = true;
      firstCard["passed"] = true;
      changeCardStatusHandler(card);
      changeCardStatusHandler(firstCard);
    } else {
      setWait(true);
      setTimeout(() => {
        changeCardStatusHandler(card);
        changeCardStatusHandler(firstCard!);
        setWait(false);
      }, 1500);
    }
  };

  const changeCardStatusHandler = async (clickedCard: CardType) => {
    if (!clickedCard.passed) clickedCard.isFlipped = !clickedCard.isFlipped;
    let index = cardsState.findIndex((card) => card.id === clickedCard.id);
    let newState = [...cardsState];
    newState.splice(index, 1, clickedCard);
    await setCardsState(newState);
  };

  const handleClick = async (e: any, clickedCard: CardType) => {
    if (!isConnected) {
      alert("Please connect your wallet");
      return;
    }

    // Started a game
    if (!startTime) {
      const tempTime = new Date().getTime() / 1000;
      setStartTime(tempTime);
    }

    console.log(wait, clickedCard.isFlipped);

    if (wait || clickedCard.isFlipped) {
      return;
    }

    if (!secondClick) {
      console.log("!secondClick");

      await setFirstCard(clickedCard);
      await setSecondClick(true);
      changeCardStatusHandler(clickedCard);
    } else {
      console.log("secondClick");

      await setSecondClick(false);
      changeCardStatusHandler(clickedCard);
      checker(clickedCard);
      setFirstCard(null);
    }

    let flag = true;
    for (let i = 0; i < cardsState.length; i++) {
      if (cardsState[i].isFlipped === false) {
        flag = false;
        break;
      }
    }

    if (flag && startTime) {
      // Completed

      const lastTime = new Date().getTime() / 1000;
      const time = lastTime - startTime;

      // Send a request to back-end API
      await axios.post(
        "http://card-game-server-hn3b.onrender.com/api/save-score",
        {
          wallet: address,
          score: time,
        }
      );

      // Refresh a game board
      for (let i = 0; i < cardsState.length; i++) {
        cardsState[i].isFlipped = false;
      }
      setCardsState(cardsState);
      setStartTime(null);
    }
  };

  return (
    <div id="GMES" className="w-full flex flex-col items-center justify-start lg:pb-[10%]">
      <div className="w-full flex items-center justify-center">
        <p
          data-aos="fade-up"
          className="lg:text-[153px] text-[80px] text-center text-[#9B5E1E] lapsus leading-none"
        >
          MEMORY GAME
        </p>
      </div>
      <section className="memory-game w-[300px] h-[300px] sm:w-[500px] sm:h-[500px] md:w-[640px] md:h-[640px] pt-4">
        {cardsState?.map((card) => {
          return (
            <Card
              key={card.id}
              card={card}
              onClick={(e: any) => handleClick(e, card)}
            />
          );
        })}
      </section>
    </div>
  );
}

export default Game;
