import React from "react";
import Marquee from "react-fast-marquee";

import ZoraParty from '../../assets/parties/zora-party-queest.jpg'
import Ball from '../../assets/parties/ball.png'

const Parties = () => {
  return (
    <section id="PARTIES" className="py-10 bg-[#E7C54F3D] sm:py-16 lg:py-24 aos-init aos-animate" data-aos="zoom-out">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="mx-auto text-center">
          <h1 className="hero-title text-6xl font-peralta text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600 stroke-black text-center">
            ALF'S PARTIES
          </h1>
        </div>

        <div className="grid items-center grid-cols-1 mt-12 gap-y-10 lg:grid-cols-5 sm:mt-20 gap-x-4">
          <div className="space-y-8 lg:pr-16 xl:pr-24 lg:col-span-2 lg:space-y-12">
            <div className="flex items-start moon-light-text bg-[#FFFFFF] rounded shadow ">

              <div className="ml-5 moon-light p-2">
                <h3 className="moon-light-description text-xl font-semibold text-yellow-700">Event Structure</h3>
                <p className="mt-3 moon-light-description">
                  <span>Over 20 weeks, exclusive parties and new Flyer Zora Alf Party mints will keep the excitement
                    alive. 10 flyers are ready, with more coming.</span>
                </p>
              </div>
            </div>

            <div className="flex items-start moon-light-text bg-[#FFFFFF] rounded shadow">

              <div className="ml-5 moon-light p-2">
                <h3 className="moon-light-description text-xl font-semibold text-yellow-700">Gamification Elements</h3>
                <p className="mt-3 moon-light-description">
                  <span>Weekly mints, events, and rewards drive participation. Collecting flyers unlocks the "Alf The
                    Croc Collection" NFT with exclusive benefits.</span>
                </p>
              </div>
            </div>

            <div className="flex items-start moon-light-text bg-[#FFFFFF] rounded shadow">

              <div className="ml-5 moon-light p-2">
                <h3 className="moon-light-description text-xl font-semibold text-yellow-700">The Alfas Club</h3>
                <p className="mt-3 moon-light-description">
                  <span>An exclusive VIP initiative for top-tier members offering a free-mint spot for the "Alf The Croc
                    Collection" NFT.</span>
                </p>
              </div>
            </div>
          </div>

          <div className="lg:col-span-3">
            <img className="w-full rounded-lg shadow-xl mb-2" src={ZoraParty} alt="Alf flyers" />
            <div data-aos="flip-right" data-aos-offset="100" data-aos-delay="30" data-aos-duration="1000" className="flex-row md:flex lg:flex xl:flex items-center justify-center gap-8 mt-8 aos-init aos-animate">
              <a href="http://zora.co/@alfthecrocodile?collection=base%3A0x54d9ade139dc3b3b09f4c2717e69ab56fb14f29f" target="_blank" className="bg-[#FF8C2F] flex items-center justify-center px-8 py-[0.300rem] text-white rounded-lg buy-button gap-2">
                <img className="w-8 h-8 object-fill" src={Ball} alt="hors" />
                <span>ZORA PARTY FLYERS</span>
              </a>
              <a href="http://zora.co/@alfthecrocodile?collection=base%3A0x12f11cf2505224d3f5c75ff67df3f709dfdff247" target="_blank" className="bg-[#FF8C2F] flex items-center justify-center px-8 py-[0.500rem] text-white rounded-lg buy-button gap-2">
                <img className="w-6 h-6 object-fill" src={Ball} alt="star" />
                <span>THE ALFAS CLUB PASS</span>
              </a>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default Parties;
