import React, { useState } from "react";
import { TbPlus, TbMinus } from "react-icons/tb";

const Faq = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faq = [
    {
      question: "What is $ALF?",
      answer:
        "Alf is the heart and soul of any party and the wild ride everyone dreams of. Get ready to ride the wave of fun with Alf, the meme coin legend.",
    },
    {
      question: "What is the official CA for the $ALF Token?",
      answer: "0x26f1bb40ea88b46ceb21557dc0ffac7b7c0ad40f",
    },
    {
      question: "What does $ALF aim to be?",
      answer:
        "$ALF aims to stand as one of the leading figures of the Base ecosystem. Through the iconic Matt Furie meme culture, $ALF seeks to expand its radiant community to every corner of the network. With big dreams, so much potential to grow, and a dedicated team working overtime, the party is only just beginning.",
    },
    {
      question: "Where can I buy $ALF?",
      answer: "You can purchase $ALF through Uniswap.",
    },
    {
      question: "How do I join the $ALF Community?",
      answer:
        'Simply chant "$ALF, hear my plea" three times at midnight during a full moon. Or, you know, just join our Telegram group and follow us on X. Whichever is easier for you.',
    },
  ];

  const handleOpenQuestion = (index: any) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div id="FAQ" className="flex flex-col w-full items-center px-4 py-8 lapsus">
      <h2 className="text-[63px] font-bold mb-8">
        FAQ<span className="text-[#2FE6FF]">?</span>
      </h2>
      <div data-aos="flip-left" className="w-full max-w-2xl space-y-4">
        {faq.map((item, index) => (
          <div
            key={index}
            className="border border-gray-300 rounded-md overflow-hidden"
          >
            <button
              onClick={() => handleOpenQuestion(index)}
              className="w-full flex justify-between items-center p-4 bg-white hover:bg-gray-50 focus:outline-none"
              aria-expanded={openIndex === index}
            >
              <span className="text-left lg:text-[30px] font-medium ">
                {item.question}
              </span>
              {openIndex === index ? (
                <TbMinus className="text-2xl text-[#2FE6FF]" />
              ) : (
                <TbPlus className="text-2xl text-[#2FE6FF]" />
              )}
            </button>
            {openIndex === index && (
              <div className="p-4 bg-gray-50 text-gray-700">
                <p>{item.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
