import React, { useState, useEffect } from "react";
import axios from "axios";

import ConnectButton from "../web3/ConnectButton";
import { useAccount } from "wagmi";

const admin = "0xc9dFb029744565afd1FD3F095539f326E44ffedf";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tab, setTab] = useState<"daily" | "weekly" | "monthly">("daily");
  const [scores, setScores] = useState<any | null>(null);
  const { address } = useAccount();

  useEffect(() => {
    getScores();
  }, []);

  const getScores = async () => {
    const response = await axios.get(
      "http://card-game-server-hn3b.onrender.com/api/leaderboard"
    );

    setScores(response.data);
  };

  const handleClear = async () => {
    await axios.get(
      "http://card-game-server-hn3b.onrender.com/api/clear-score"
    );

    getScores();
  };

  const images = [
    "img/crocodile/dexeagle.png",
    "img/crocodile/tg.png",
    "img/crocodile/X.png",
    "img/crocodile/inst1.png",
    "img/crocodile/tik.png",
  ];

  let dailyContent, weeklyContent, monthlyContent;
  if (scores && scores.dailyScores) {
    dailyContent = scores.dailyScores.map((item: any, idx: number) => {
      return (
        <tr>
          <td>{idx + 1}</td>
          <td>{item.wallet}</td>
          <td>{Number(item.score).toFixed(2)}</td>
        </tr>
      );
    });
  }

  if (scores && scores.weeklyScores) {
    weeklyContent = scores.weeklyScores.map((item: any, idx: number) => {
      return (
        <tr>
          <td>{idx + 1}</td>
          <td>{item.wallet}</td>
          <td>{Number(item.score).toFixed(2)}</td>
        </tr>
      );
    });
  }

  if (scores && scores.monthlyScores) {
    monthlyContent = scores.monthlyScores.map((item: any, idx: number) => {
      return (
        <tr>
          <td>{idx + 1}</td>
          <td>{item.wallet}</td>
          <td>{Number(item.score).toFixed(2)}</td>
        </tr>
      );
    });
  }

  return (
    <div className="relative">
      <div className="bg-[#9B5E1E] h-[90px] z-[1] relative rounded-lg top-1 w-full">
        <div className="text-white bg-white absolute w-full right-1 top-[-5px] rounded-lg flex lg:flex-row flex-col items-center lg:justify-between z-[10] px-[4%] py-6">
          <img width={150} src="/img/crocodile/logo.png" alt="" />
          <div className="lg:flex hidden flex-row items-center gap-6 text-[#9B5E1E] font-bold lapsus  lg:text-[20px]">
            <a href={"#ALF?"}>ALF?</a>
            <a href={"#PARTIES"}>PARTIES</a>
            <a href={"#TOKENOMYCS"}>TOKENOMYCS</a>
            <a href={"#GMES"}>GMES</a>
            <a href={"#MUSIC"}>MUSIC</a>
            <a href={"#MERCH"}>MERCH</a>
            <a href={"#FAQ"}>FAQ</a>
            <a href="#" onClick={() => setIsOpen(true)}>
              LEADERBOARD
            </a>
          </div>
          <div className="flex flex-row gap-4 lg:w-[20%] w-full items-center lg:justify-end justify-center">
            {images.map((image, index) => (
              <img
                width={50}
                className="flex cursor-pointer lg:w-[60%] w-[10%]"
                key={index}
                src={image}
                alt=""
              />
            ))}
            <div className="bg-[#3B82F6] rounded-[20px]">
              <ConnectButton />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`relative z-10 ${!isOpen && "hidden"}`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="p-5 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md lg:max-w-xl xl:max-w-2xl">
              <h1 className="font-bold text-2xl text-center">LEADERBOARD</h1>
              <ul className="mt-6 text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
                <li className="w-full focus-within:z-10">
                  <a
                    href="#"
                    className={`inline-block w-full p-4 ${
                      tab === "daily" ? "text-gray-900 bg-gray-100" : "bg-white"
                    }  border-r border-gray-200 dark:border-gray-700 rounded-s-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none dark:bg-gray-700 dark:text-white`}
                    aria-current="page"
                    onClick={() => setTab("daily")}
                  >
                    Daily
                  </a>
                </li>
                <li className="w-full focus-within:z-10">
                  <a
                    href="#"
                    aria-current="page"
                    onClick={() => setTab("weekly")}
                    className={`inline-block w-full p-4 ${
                      tab === "weekly"
                        ? "text-gray-900 bg-gray-100"
                        : "bg-white"
                    } border-r border-gray-200 dark:border-gray-700 hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700`}
                  >
                    Weekly
                  </a>
                </li>
                <li className="w-full focus-within:z-10">
                  <a
                    href="#"
                    aria-current="page"
                    onClick={() => setTab("monthly")}
                    className={`inline-block w-full p-4 ${
                      tab === "monthly"
                        ? "text-gray-900 bg-gray-100"
                        : "bg-white"
                    } border-r border-gray-200 dark:border-gray-700 hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700`}
                  >
                    Monthly
                  </a>
                </li>
              </ul>
              <div className="p-4 bg-[#9B5E1E] mt-4 rounded-lg">
                {tab === "daily" && (
                  <table className="w-full table-auto">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>User</th>
                        <th>Score(sec)</th>
                      </tr>
                    </thead>
                    <tbody className="text-white">{dailyContent}</tbody>
                  </table>
                )}
                {tab === "weekly" && (
                  <table className="w-full table-auto">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>User</th>
                        <th>Score(sec)</th>
                      </tr>
                    </thead>
                    <tbody className="text-white">{weeklyContent}</tbody>
                  </table>
                )}
                {tab === "monthly" && (
                  <table className="w-full table-auto">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>User</th>
                        <th>Score(sec)</th>
                      </tr>
                    </thead>
                    <tbody className="text-white">{monthlyContent}</tbody>
                  </table>
                )}
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-3">
                {admin === address && (
                  <button
                    onClick={handleClear}
                    className="px-10 py-2 text-[18px] bg-[#FF8C2F] peralta-regular text-white rounded-lg "
                  >
                    CLEAR BOARD
                  </button>
                )}
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
