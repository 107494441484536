import React from "react";
import Marquee from "react-fast-marquee";

const Whopage = () => {
  return (
    <div id="ALF?" className="w-full h-[100vh] flex flex-col bg-white items-center lg:justify-center">
      <div className="flex flex-row items-center gap-8  lg:pl-[15%]">
        <img
          data-aos="flip-down"
          className="lg:w-full w-[50%]"
          src="/img/crocodile/who_img.gif"
          alt=""
        />
        <div data-aos="flip-down" className="">
          <span className="flex items-center gap-4 flex-row">
            <p className="lg:text-[100px] text-[#212529] lapsus">
              Who’s <span className="text-[#FF8C2F]">Alf</span>
            </p>
            <img width={50} src="/img/crocodile/question.png" alt="" />
          </span>
          <p className="lg:w-[80%] peralta-regular lg:text-[25px]">
            ALF is a meme coin inspired by Alf the crocodile, a wild character
            created by Matt Furie. Alf is all about fun, chaos, and good times.
            He’s brave, loyal, and always in for a party with his flashy
            lifestyle. If you’re into fun and edgy crypto, ALF might be for you.
          </p>
        </div>
      </div>
      <div data-aos="zoom-in" className="">
        <Marquee className="flex flex-row items-center lg:text-[100px] text-[40px] lapsus">
          <p className="mr-4">ALF IS THE BEST FRIEND OF THE BOY’S CLUB</p>
          <p className="mr-4">ALF IS THE BEST FRIEND OF THE BOY’S CLUB</p>
        </Marquee>
      </div>
    </div>
  );
};

export default Whopage;
