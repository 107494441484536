import React from "react";
import { TbChevronRight } from "react-icons/tb";

const Fames = () => {
  const data = [
    {
      img: "/img/crocodile/rise.png",
      title: "Rise to Fame",
      content: "Alf The Crocodile | Official 🐊",
    },
    {
      img: "/img/crocodile/rise.png",
      title: "Alf the green croc",
      content: "Alf The Crocodile | Official 🐊",
    },
    {
      img: "/img/crocodile/rise.png",
      title: "The Party King",
      content: "Alf The Crocodile | Official 🐊",
    },
  ];
  return (
    <div id="MUSIC" className="w-full flex items-center justify-center h-[100vh]">
      <div
        data-aos="zoom-in-up"
        className="bg-[#7147BC] lg:w-[30%] h-[60vh] rounded-2xl p-4"
      >
        <div className="flex flex-col gap-4 ">
          {data.map((d, i) => (
            <div
              key={i}
              className="flex w-full shadow-2xl rounded-xl py-3 px-2 justify-between poppins"
            >
              <div className="flex flex-row items-center gap-4">
                <img width={50} src={d.img} alt="" />
                <div className="flex flex-col gap-2">
                  <p className="text-[#FCFBFD] font-bold text-[18px]">
                    {d.title}
                  </p>
                  <p className="text-[#AB92D7]">{d.content}</p>
                </div>
              </div>
              <div className="h-[50px] w-[50px] rounded-full shadow-lg flex items-center justify-center">
                <TbChevronRight className="text-white text-2xl font-bold" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Fames;
