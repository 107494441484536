import React from "react";

const Tokenomycs = () => {
  return (
    <div
      id="TOKENOMYCS"
      data-aos="zoom-in"
      className="w-full lg:h-[100vh] px-[6%] bg-white flex items-center justify-center lg:mb-0 mb-20"
    >
      <div className="w-full bg-[#F9F1D5] lg:h-[95vh] rounded-lg p-7 relative flex flex-col justify-between">
        <div className="w-full justify-between flex lg:flex-row flex-col-reverse items-center ">
          <p
            data-aos="zoom-in-left"
            className="lg:text-[130px] text-[50px] font-bold lapsus"
          >
            TOKENOMYCS
          </p>
          <div className="flex flex-row items-center gap-3">
            <div className="w-[90px] h-[90px] rounded-full items-center justify-center  flex bg-[#E7C54F]">
              <p className=" text-center peralta-regular">
                0% <br /> BUY <br /> TAX
              </p>
            </div>
            <div className="w-[90px] h-[90px] rounded-full items-center justify-center  flex bg-[#E7C54F]">
              <p className="text-center peralta-regular">
                0% <br /> SELL <br /> TAX
              </p>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center items-center ">
          <div className="py-4 w-[80%] text-[30px] flex flex-col items-center lg:justify-center justify-start gap-3 peralta-regular rounded-lg lg:h-fit h-[90vh] bg-white border-[1px] border-black">
            <p data-aos="zoom-in-left" className="lg:text-[16px] text-[13px]">
              85% LP
            </p>
            <p data-aos="zoom-in-left" className="lg:text-[16px] text-[13px]">
              5% Marketing
            </p>
            <p data-aos="zoom-in-left" className="lg:text-[16px] text-[13px]">
              5% CEX
            </p>
            <p data-aos="zoom-in-left" className="lg:text-[16px] text-[13px]">
              3% Team
            </p>
            <p data-aos="zoom-in-left" className="lg:text-[16px] text-[13px]">
              2% Dev
            </p>
            <div className="h-[3px] w-[35%] bg-[#E6D7C7]"></div>
            <div className="flex lg:flex-row flex-col gap-6 items-center">
              <span className="text-[15px] flex flex-col items-center lapsus">
                <p>LP LOCKED</p>
                <p>(6 MONTHS INITIALLY)</p>
              </span>
              <button className="px-10 py-2 text-[18px] bg-[#FF8C2F] peralta-regular text-white rounded-lg ">
                CHECK HERE
              </button>
            </div>
          </div>
        </div>

        <img
          src="/img/crocodile/crocodile.png"
          className="absolute lg:bottom-0 lg:right-0 lg:w-[35%] md:w-[40%] w-[60%] bottom-8 right-[110px]"
          alt=""
        />
      </div>
    </div>
  );
};

export default Tokenomycs;
