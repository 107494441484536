import React from "react";
import Navbar from "./Navbar";
import bg from "../../assets/bg.jpg";

const Hero = () => {
  return (
    <div
      className="lg:px-5 lg:pt-[6%] bg-black h-[130vh]"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      }}
    >
      <Navbar />
      <div className="w-full relative h-[100vh] flex flex-col items-center justify-center lg:gap-6 gap-3">
        <p className="lg:text-[96px] text-[80px] text-[#F29511] text-center peralta-regular leading-none">
          ALF The <br className="" /> Croc
        </p>
        <p className="text-white lg:w-[40%] text-center lapsus lg:text-[20px] leading-[32px]">
          ALF is a meme coin inspired by Alf the crocodile, a wild character
          created by Matt Furie. Alf is all about fun, chaos, and good times.
          He’s brave, loyal, and always in for a party with his flashy
          lifestyle. If you’re into fun and edgy crypto, ALF might be for you.
        </p>

        <div data-aos="fade-up" className="relative lg:w-[20%] w-[60%] ">
          <button className="bg-white absolute peralta-regular lg:text-[31px] text-black w-full z-[5] rounded-xl h-[70px]">
            <p>Copy CA</p>
          </button>
          <button className="bg-[#E7C54F] absolute left-2 top-2 text-black z-[1] w-full rounded-xl h-[70px]"></button>
        </div>
      </div>
      {/* <div ></div> */}
    </div>
  );
};

export default Hero;
