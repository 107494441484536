import g1 from "../../assets/game/g1.png";
import g2 from "../../assets/game/g2.png";
import g3 from "../../assets/game/g3.png";
import g4 from "../../assets/game/g4.png";
import g5 from "../../assets/game/g5.png";
import g6 from "../../assets/game/g6.png";

const cards = [
  { id: 1, name: "g1", image: g1 },
  { id: 2, name: "g1", image: g1 },
  { id: 3, name: "g2", image: g2 },
  { id: 4, name: "g2", image: g2 },
  { id: 5, name: "g3", image: g3 },
  { id: 6, name: "g3", image: g3 },
  { id: 7, name: "g4", image: g4 },
  { id: 8, name: "g4", image: g4 },
  { id: 9, name: "g5", image: g5 },
  { id: 10, name: "g5", image: g5 },
  { id: 11, name: "g6", image: g6 },
  { id: 12, name: "g6", image: g6 },
];

export const cardsData = cards.map((card) => ({
  ...card,
  order: Math.floor(Math.random() * 12),
  isFlipped: false,
}));
