import React from "react";

const Footer = () => {
  const images = [
    "img/crocodile/dexeagle.png",
    "img/crocodile/tg.png",
    "img/crocodile/X.png",
    "img/crocodile/inst1.png",
    "img/crocodile/tik.png",
  ];
  return (
    <div className="px-3 w-full pb-4">
      <div className="flex lg:flex-row flex-col lg:gap-0 gap-3 justify-between items-center w-full">
        <span className="flex flex-col lg:items-start items-center gap-2">
          <img src="/img/crocodile/logo.png" alt="" />
          <p className="lg:text-[18px] text-[14px]">
            Contact Address:{" "}
            <span>0x26f1bb40ea88b46ceb21557dc0ffac7b7c0ad40f</span>
          </p>
          <p className="lg:text-[18px] text-[14px]">
            Official email: <span>info@alfthecrocodile.com</span>
          </p>
          <p></p>
        </span>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-3 ">
            {images.map((image, index) => (
              <img
                width={50}
                className="flex cursor-pointer w-[60%]"
                key={index}
                src={image}
                alt=""
              />
            ))}
          </div>
          <p>©2024 ALF the crocodile. All Rights Reserved.</p>
        </div>
      </div>
      <div className="w-full text-center lg:text-[26px] items-center justify-center flex flex-col lg:mt-[4%] peralta-regular text-[#9B5E1E]">
        <p className="lg:w-[60%]">
          $ALF coin has no association with Matt Furie or his creation ALF the
          crocodile. This token is simply paying homage to a meme we all love
          and recognize.
        </p>
        <p className="lg:w-[60%]">
          $ALF is a meme coin with no intrinsic value or expectation of
          financial return. There is no formal team or roadmap. the coin is
          completely useless and for entertainment purposes only.
        </p>
      </div>
    </div>
  );
};

export default Footer;
