import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";

import { WagmiProvider } from "wagmi";
import { base } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// 0. Setup queryClient
const queryClient = new QueryClient();

// 1. Get projectId from http://cloud.walletconnect.com
const projectId = "c12e0b5dc354ac1b746cec01d47b5b6a";

// 2. Create wagmiConfig
const metadata = {
  name: "Alf the Crocodile",
  description: "This is a intro website for Alf the Crocodile",
  url: "http://localhost:3000", // origin must match your domain & subdomain
  icons: ["http://avatars.githubusercontent.com/u/37784886"],
};

const chains = [base] as const;
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
});

// 3. Create modal
createWeb3Modal({
  metadata,
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

export function Web3ModalProvider({ children }: any) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
}
