import React from "react";
import Marquee from "react-fast-marquee";

const Partners = () => {
  return (
    <div
      data-aos="flip-left"
      className="bg-[#FC9037] h-[100px] flex items-center justify-center w-full "
    >
      <Marquee className="flex w-full">
        <img className="mr-[10rem]" src="/img/crocodile/w.png" alt="" />
        <img className="mr-[10rem]" src="/img/crocodile/world.png" alt="" />
        <img className="mr-[10rem]" src="/img/crocodile/wifi.png" alt="" />
        <img className="mr-[10rem]" src="/img/crocodile/w.png" alt="" />
        <img className="mr-[10rem]" src="/img/crocodile/w.png" alt="" />
        <img className="mr-[10rem]" src="/img/crocodile/w.png" alt="" />
      </Marquee>
    </div>
  );
};

export default Partners;
