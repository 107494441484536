import React from "react";
import Marquee from "react-fast-marquee";

import Sticker from '../../assets/merch/alf_sticker.webp'
import Mug from '../../assets/merch/alf_mug.webp'
import Hat from '../../assets/merch/alf_hat.webp'
import Hoddie from '../../assets/merch/alf_hoddie.webp'
import Logo from '../../assets/merch/logodonsato.png'

const Merch = () => {
  return (
    <section id="MERCH" className="container mx-auto p-10 md:py-12 px-0 md:p-8 md:px-0">
      <div data-aos="fade-up" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000" data-aos-easing="ease-in-up" className="moon-light-text mt-10 aos-init aos-animate pb-10">
        <h1 className="hero-title text-6xl font-peralta text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600 stroke-black text-center">
          ALF'S MERCHANDISING
        </h1>
      </div>
      <section className="p-5 md:p-0 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-10 items-start ">
          <section className="p-5 py-10 bg-orange-50 text-center transform duration-500 hover:-translate-y-2 cursor-pointer">
              <img src={Sticker} alt="" />
              <div className="space-x-1 flex justify-center mt-10">
                  <img src={Logo} alt="" />
              </div>
              <h1 className="text-2xl my-5">ALF'S HOLOGRAPHIC SHADES STICKER</h1>

              <h2 className="font-semibold mb-5">5.00€</h2>
              <a href="http://donsato.com" target="_blank"><button className="p-2 px-6 bg-purple-500 text-white rounded-md hover:bg-purple-600">BUY NOW ON DONSATO.COM</button></a>
          </section>
  
          <section className="p-5 py-10 bg-orange-50 text-center transform duration-500 hover:-translate-y-2 cursor-pointer">
              <img src={Mug} alt="" />
              <div className="space-x-1 flex justify-center mt-10">
                  <img src={Logo} alt="" />
              </div>
              <h1 className="text-2xl my-5">GM F CROCS COFFEE MUG FOR ALFAS</h1>
              <h2 className="font-semibold mb-5">25.00€</h2>
              <a href="http://donsato.com" target="_blank"><button className="p-2 px-6 bg-green-500 text-white rounded-md hover:bg-green-600">BUY NOW ON DONSATO.COM</button></a>
          </section>
  
          <section className="p-5 py-10 bg-orange-50 text-center transform duration-500 hover:-translate-y-2 cursor-pointer">
              <img src={Hat} alt="" />
              <div className="space-x-1 flex justify-center mt-10">
                  <img src={Logo} alt="" />
              </div>
              <h1 className="text-2xl my-5">ALF'S SHADES SNAPBACK HAT</h1>
              <h2 className="font-semibold mb-5">42.00€</h2>
              <a href="http://donsato.com" target="_blank"><button className="p-2 px-6 bg-red-500 text-white rounded-md hover:bg-red-600">BUY NOW ON DONSATO.COM</button></a>
          </section>
  
          <section className="p-5 py-10 bg-orange-50 text-center transform duration-500 hover:-translate-y-2 cursor-pointer">
              <img src={Hoddie} alt="" />
              <div className="space-x-1 flex justify-center mt-10">
                  <img src={Logo} alt="" />
              </div>
              <h1 className="text-2xl my-5">ALF THE CROCODILE LOGO HOODIE</h1>
              <h2 className="font-semibold mb-5">65.00€</h2>
              <a href="http://donsato.com" target="_blank"><button className="p-2 px-6 bg-blue-500 text-white rounded-md hover:bg-blue-600">BUY NOW ON DONSATO.COM</button></a>
          </section>
      </section>
  </section>
  );
};

export default Merch;
