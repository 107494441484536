import React, { useEffect } from "react";

// Import Wallet Provider
import { Web3ModalProvider } from "./provider/Web3ModalProvider";

// Import Sections (custom components)
import Hero from "./components/sections/Hero";
import Partners from "./components/sections/Partners";
import Whopage from "./components/sections/Whopage";
import Parties from './components/sections/Parties'
import Merch from './components/sections/Merch'
import Tokenomycs from "./components/sections/Tokenomycs";
import Game from "./components/sections/Game";
import Faq from "./components/sections/Faq";
import Moonlit from "./components/sections/Moonlit";
import Befriend from "./components/sections/Befriend";
import Fames from "./components/sections/Fames";
import Footer from "./components/sections/Footer";

import Aos from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init({
      easing: "ease-in-out",
      duration: 800,
    });
  }, []);

  return (
    <Web3ModalProvider>
      <div className="w-full overflow-x-hidden">
        <Hero />
        <Partners />
        <Whopage />
        <Parties />
        <Tokenomycs />
        <Game />
        <Merch />
        <Faq />
        <Moonlit />
        <Befriend />
        <Fames />
        <Footer />
      </div>
    </Web3ModalProvider>
  );
}

export default App;
