import React from "react";

const Moonlit = () => {
  return (
    <div className="w-full lg:h-[100vh] h-[50vh] flex flex-col items-center py-[4%] lg:px-0 px-2 gap-3 lg:pb-0 pb-6">
      <p
        data-aos="fade-up"
        className="lg:text-[153px] text-[60px] text-[#9B5E1E] text-center leading-none lapsus"
      >
        FEEL THE <br />
        MOONLIT TOUCH
      </p>
      <p
        data-aos="flip-left"
        className="lg:text-[26px] text-center lg:w-[55%] peralta-regular"
      >
        In Alf we trust, for the crocodile is the swift attacker and the silent
        guardian, the master of patience and the emblem of power, all rolled
        into one enduring force.
      </p>
      <div className="flex lg:flex-row flex-col justify-center items-center gap-5 peralta-regular w-full">
        <button
          data-aos="zoom-in-down"
          className="py-1 lg:px-8 lg:w-fit justify-center w-full items-center flex flex-row gap-3 bg-[#FF8C2F] rounded-lg"
        >
          <img src="/img/crocodile/uniswap.png" alt="" />
          <p className="lg:text-[16px] text-[10px] text-white">
            Buy on Uniswap
          </p>
        </button>
        <button
          data-aos="zoom-in-left"
          className="py-1 lg:px-8 lg:w-fit justify-center w-full items-center flex flex-row gap-3 bg-[#FF8C2F] rounded-lg"
        >
          <img src="/img/crocodile/uniswap.png" alt="" />
          <p className="lg:text-[16px] text-[10px] text-white">Buy on Flooz</p>
        </button>
      </div>
    </div>
  );
};

export default Moonlit;
