// Import Assets
import bg from "../../assets/game/g.png";

// Import Interface
import { CardType } from "../../types";

function Card({ card, onClick }: { card: CardType; onClick: any }) {
  return (
    <div
      className={`memory-card${card.isFlipped ? " flip" : ""}`}
      onClick={onClick}
      style={{ order: card.order }}
      data-testid={card.id}
    >
      <img className="front-face" src={card.image} alt="Card" />
      <img className="back-face" src={bg} alt="Background Image" />
    </div>
  );
}

export default Card;
